var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
import { __decorate, __metadata } from "tslib";
import { CONTROLLERNAMES } from "@/common/types/ControllerNames";
import { SelectListVm } from "@/models/SelectListVm";
import { BusinessAreaVm } from "@/models/businessArea";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ddpService } from "@/services/DdpService";
import { MUTATION_TYPES } from "@/store/types/mutation-types";
import { ACTION_TYPES } from "@/store/types/action-types";
import { PaginationRequest } from "@/models/Pagination";
import { FailActionResult } from "@/common/helpers/ApiHelper";
import { STORE_MODULE_NAMES } from "@/store/types/store-module-names";
let BusinessArea = class BusinessArea extends VuexModule {
    businessAreas = [];
    businessArea = null;
    pagination = new PaginationRequest();
    sort = "";
    filterMap = new Map();
    businessAreaSelectList = [];
    isAuthorized = false;
    error = null;
    paginationActive = true;
    [_b = MUTATION_TYPES.SET_ERROR](error) {
        this.error = error;
    }
    [_c = MUTATION_TYPES.SET_SORT](value) {
        this.sort = value;
    }
    [_e = MUTATION_TYPES.SET_PAGINATION](data) {
        this.pagination = data;
    }
    [_g = MUTATION_TYPES.SET_FILTERMAP](filterMap) {
        this.filterMap = filterMap;
    }
    [_h = MUTATION_TYPES.SET_SELECTLIST](data) {
        this.businessAreaSelectList = [...data];
    }
    async [_j = ACTION_TYPES.GET_SELECTLIST](refresh = false) {
        const result = await ddpService.getAllItems(CONTROLLERNAMES.BUSINESS_AREA);
        if (result.isSuccess) {
            const businessAreas = this.businessAreaSelectList.length > 0 && !refresh
                ? this.businessAreaSelectList
                : result.result[Object.getOwnPropertyNames(result.result)[0]].map((x) => {
                    return SelectListVm.mapFromBusinessAreaVm(new BusinessAreaVm(x));
                });
            this.context.commit(MUTATION_TYPES.SET_SELECTLIST, businessAreas);
            this.businessAreaSelectList.sort((a, b) => a.name.localeCompare(b.name));
        }
    }
    [_k = MUTATION_TYPES.SET_PAGEDLIST](data) {
        this.businessAreas = [...data];
    }
    async [_m = ACTION_TYPES.GET_PAGEDLIST](pagination) {
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, true, {
            root: true,
        });
        const result = await ddpService.getAllItems(CONTROLLERNAMES.BUSINESS_AREA, pagination, this.sort, this.filterMap);
        if (result.isSuccess) {
            const businessAreas = result.result[Object.getOwnPropertyNames(result.result)[0]].map((x) => {
                return new BusinessAreaVm(x);
            });
            pagination.setMetadata(result.result);
            this.context.commit(MUTATION_TYPES.SET_PAGINATION, pagination);
            this.context.commit(MUTATION_TYPES.SET_PAGEDLIST, businessAreas);
        }
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, false, {
            root: true,
        });
    }
    [_p = MUTATION_TYPES.SET_BY_ID](data) {
        this.businessArea = Object.assign({}, data);
    }
    async [_q = ACTION_TYPES.GET_BY_ID](id) {
        if (id === 0)
            return;
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, true, {
            root: true,
        });
        const result = await ddpService.getItemById(CONTROLLERNAMES.BUSINESS_AREA, id);
        if (result.isSuccess) {
            this.context.commit(MUTATION_TYPES.SET_BY_ID, result.result);
        }
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, false, {
            root: true,
        });
    }
    async [_t = ACTION_TYPES.ADD](data) {
        //Call api
        const result = await ddpService.addItem(CONTROLLERNAMES.BUSINESS_AREA, data, STORE_MODULE_NAMES.BUSINESSAREA);
        if (result.isSuccess) {
            this.context.dispatch(ACTION_TYPES.GET_SELECTLIST, true);
        }
        return result;
    }
    async [_w = ACTION_TYPES.EDIT](data) {
        //Call api
        const result = await ddpService.editItem(CONTROLLERNAMES.BUSINESS_AREA, data, STORE_MODULE_NAMES.BUSINESSAREA);
        if (result.isSuccess) {
            this.context.dispatch(ACTION_TYPES.GET_SELECTLIST, true);
        }
        return result;
    }
    async [_y = ACTION_TYPES.DELETE_ITEM](id) {
        const result = await ddpService.deleteItem(id, CONTROLLERNAMES.BUSINESS_AREA);
        if (result.isSuccess) {
            this.context.dispatch(ACTION_TYPES.GET_SELECTLIST, true);
        }
        return result.isSuccess;
    }
};
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof FailActionResult !== "undefined" && FailActionResult) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], BusinessArea.prototype, _b, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], BusinessArea.prototype, _c, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof PaginationRequest !== "undefined" && PaginationRequest) === "function" ? _d : Object]),
    __metadata("design:returntype", void 0)
], BusinessArea.prototype, _e, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_f = typeof Map !== "undefined" && Map) === "function" ? _f : Object]),
    __metadata("design:returntype", void 0)
], BusinessArea.prototype, _g, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], BusinessArea.prototype, _h, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], BusinessArea.prototype, _j, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], BusinessArea.prototype, _k, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_l = typeof PaginationRequest !== "undefined" && PaginationRequest) === "function" ? _l : Object]),
    __metadata("design:returntype", Promise)
], BusinessArea.prototype, _m, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_o = typeof BusinessAreaVm !== "undefined" && BusinessAreaVm) === "function" ? _o : Object]),
    __metadata("design:returntype", void 0)
], BusinessArea.prototype, _p, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", Promise)
], BusinessArea.prototype, _q, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_r = typeof BusinessAreaVm !== "undefined" && BusinessAreaVm) === "function" ? _r : Object]),
    __metadata("design:returntype", typeof (_s = typeof Promise !== "undefined" && Promise) === "function" ? _s : Object)
], BusinessArea.prototype, _t, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_u = typeof BusinessAreaVm !== "undefined" && BusinessAreaVm) === "function" ? _u : Object]),
    __metadata("design:returntype", typeof (_v = typeof Promise !== "undefined" && Promise) === "function" ? _v : Object)
], BusinessArea.prototype, _w, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", typeof (_x = typeof Promise !== "undefined" && Promise) === "function" ? _x : Object)
], BusinessArea.prototype, _y, null);
BusinessArea = __decorate([
    Module({ namespaced: true })
], BusinessArea);
export default BusinessArea;
