var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
import { __decorate, __metadata } from "tslib";
import { FailActionResult } from "@/common/helpers/ApiHelper";
import { CONTROLLERNAMES } from "@/common/types/ControllerNames";
import { isNullOrUndefined } from "@/common/Utils";
import { PaginationRequest } from "@/models/Pagination";
import { PmnVm } from "@/models/pmn";
import { SelectListVm } from "@/models/SelectListVm";
import { ddpService } from "@/services/DdpService";
import { ACTION_TYPES } from "@/store/types/action-types";
import { MUTATION_TYPES } from "@/store/types/mutation-types";
import { STORE_MODULE_NAMES } from "@/store/types/store-module-names";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
let PrintedMatterNumber = class PrintedMatterNumber extends VuexModule {
    pmns = [];
    pmn = null;
    pagination = new PaginationRequest();
    sort = "";
    filterMap = new Map();
    error = null;
    paginationActive = false;
    pmnSelectList = [];
    get getPmnEntity() {
        if (!isNullOrUndefined(this.pmn)) {
            return this.pmn;
        }
    }
    [_b = MUTATION_TYPES.SET_ERROR](error) {
        this.error = error;
    }
    [_c = MUTATION_TYPES.SET_ALL_ENTITIES](data) {
        if (!isNullOrUndefined(data)) {
            this.pmns = [...data];
        }
    }
    async [_d = ACTION_TYPES.GET_ALL_ENTITIES]() {
        const result = await ddpService.getAllItems(CONTROLLERNAMES.PMN);
        if (result.isSuccess) {
            const data = result.result[Object.getOwnPropertyNames(result.result)[0]].map((x) => {
                return new PmnVm(x);
            });
            this.context.commit(MUTATION_TYPES.SET_ALL_ENTITIES, data);
        }
    }
    [_e = MUTATION_TYPES.SET_SELECTLIST](data) {
        this.pmnSelectList = [...data];
    }
    async [_f = ACTION_TYPES.GET_SELECTLIST]() {
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, true, {
            root: true,
        });
        const result = await ddpService.getAllItems(CONTROLLERNAMES.PMN);
        if (result.isSuccess) {
            const PrintedMatterNumbers = result.result[Object.getOwnPropertyNames(result.result)[0]].map((x) => {
                return SelectListVm.mapFromPmnVm(new PmnVm(x));
            });
            this.context.commit(MUTATION_TYPES.SET_SELECTLIST, PrintedMatterNumbers);
        }
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, false, {
            root: true,
        });
    }
    [_h = MUTATION_TYPES.SET_BY_ID](data) {
        this.pmn = Object.assign({}, data);
    }
    async [_j = ACTION_TYPES.GET_BY_ID](id) {
        if (id === 0)
            return;
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, true, {
            root: true,
        });
        const result = await ddpService.getItemById(CONTROLLERNAMES.PMN, id);
        if (result.isSuccess) {
            this.context.commit(MUTATION_TYPES.SET_BY_ID, result.result);
        }
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, false, {
            root: true,
        });
    }
    async [_m = ACTION_TYPES.ADD](data) {
        //Call api
        const result = await ddpService.addItem(CONTROLLERNAMES.PMN, data, STORE_MODULE_NAMES.PMN);
        if (result.isSuccess) {
            this.context.dispatch(ACTION_TYPES.GET_SELECTLIST, true);
        }
        return result;
    }
    async [_q = ACTION_TYPES.EDIT](data) {
        //Call api
        const result = await ddpService.editItem(CONTROLLERNAMES.PMN, data, STORE_MODULE_NAMES.PMN);
        if (result.isSuccess) {
            this.context.dispatch(ACTION_TYPES.GET_SELECTLIST, true);
        }
        return result;
    }
    async [_s = ACTION_TYPES.DELETE_ITEM](id) {
        const result = await ddpService.deleteItem(id, CONTROLLERNAMES.PMN);
        if (result.isSuccess) {
            this.context.dispatch(ACTION_TYPES.GET_SELECTLIST, true);
        }
        return result.isSuccess;
    }
};
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof FailActionResult !== "undefined" && FailActionResult) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], PrintedMatterNumber.prototype, _b, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], PrintedMatterNumber.prototype, _c, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], PrintedMatterNumber.prototype, _d, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], PrintedMatterNumber.prototype, _e, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], PrintedMatterNumber.prototype, _f, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_g = typeof PmnVm !== "undefined" && PmnVm) === "function" ? _g : Object]),
    __metadata("design:returntype", void 0)
], PrintedMatterNumber.prototype, _h, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", Promise)
], PrintedMatterNumber.prototype, _j, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_k = typeof PmnVm !== "undefined" && PmnVm) === "function" ? _k : Object]),
    __metadata("design:returntype", typeof (_l = typeof Promise !== "undefined" && Promise) === "function" ? _l : Object)
], PrintedMatterNumber.prototype, _m, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_o = typeof PmnVm !== "undefined" && PmnVm) === "function" ? _o : Object]),
    __metadata("design:returntype", typeof (_p = typeof Promise !== "undefined" && Promise) === "function" ? _p : Object)
], PrintedMatterNumber.prototype, _q, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", typeof (_r = typeof Promise !== "undefined" && Promise) === "function" ? _r : Object)
], PrintedMatterNumber.prototype, _s, null);
PrintedMatterNumber = __decorate([
    Module({ namespaced: true })
], PrintedMatterNumber);
export default PrintedMatterNumber;
