var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
import { __decorate, __metadata } from "tslib";
import { FailActionResult } from "@/common/helpers/ApiHelper";
import { CONTROLLERNAMES } from "@/common/types/ControllerNames";
import { BrandVm } from "@/models/brand";
import { PaginationRequest } from "@/models/Pagination";
import { SelectListVm } from "@/models/SelectListVm";
import { ddpService } from "@/services/DdpService";
import { ACTION_TYPES } from "@/store/types/action-types";
import { MUTATION_TYPES } from "@/store/types/mutation-types";
import { STORE_MODULE_NAMES } from "@/store/types/store-module-names";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
let Brand = class Brand extends VuexModule {
    brands = [];
    brand = null;
    pagination = new PaginationRequest();
    brandSelectList = [];
    sort = "";
    filterMap = new Map();
    error = null;
    paginationActive = true;
    [_b = MUTATION_TYPES.SET_ERROR](error) {
        this.error = error;
    }
    [_c = MUTATION_TYPES.SET_SORT](value) {
        this.sort = value;
    }
    [_e = MUTATION_TYPES.SET_PAGINATION](data) {
        this.pagination = data;
    }
    [_g = MUTATION_TYPES.SET_FILTERMAP](filterMap) {
        this.filterMap = filterMap;
    }
    [_h = MUTATION_TYPES.SET_SELECTLIST](data) {
        this.brandSelectList = [...data];
    }
    async [_j = ACTION_TYPES.GET_SELECTLIST](refresh = false) {
        const result = await ddpService.getAllItems(CONTROLLERNAMES.BRAND);
        if (result.isSuccess) {
            const brands = this.brandSelectList.length > 0 && !refresh
                ? this.brandSelectList
                : result.result[Object.getOwnPropertyNames(result.result)[0]].map((x) => {
                    return SelectListVm.mapFromBrandVm(new BrandVm(x));
                });
            this.context.commit(MUTATION_TYPES.SET_SELECTLIST, brands);
            this.brandSelectList.sort((a, b) => a.name.localeCompare(b.name));
        }
    }
    [_k = MUTATION_TYPES.SET_PAGEDLIST](data) {
        this.brands = [...data];
    }
    async [_m = ACTION_TYPES.GET_PAGEDLIST](pagination) {
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, true, {
            root: true,
        });
        const result = await ddpService.getAllItems(CONTROLLERNAMES.BRAND, pagination, this.sort, this.filterMap);
        if (result.isSuccess) {
            const brands = result.result[Object.getOwnPropertyNames(result.result)[0]].map((x) => {
                return new BrandVm(x);
            });
            pagination.setMetadata(result.result);
            this.context.commit(MUTATION_TYPES.SET_PAGINATION, pagination);
            this.context.commit(MUTATION_TYPES.SET_PAGEDLIST, brands);
        }
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, false, {
            root: true,
        });
    }
    [_p = MUTATION_TYPES.SET_BY_ID](data) {
        this.brand = Object.assign({}, data);
    }
    async [_q = ACTION_TYPES.GET_BY_ID](id) {
        if (id === 0)
            return;
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, true, {
            root: true,
        });
        const result = await ddpService.getItemById(CONTROLLERNAMES.BRAND, id);
        if (result.isSuccess) {
            this.context.commit(MUTATION_TYPES.SET_BY_ID, result.result);
        }
        this.context.commit(`${MUTATION_TYPES.SET_GETTINGDATA}`, false, {
            root: true,
        });
    }
    async [_t = ACTION_TYPES.ADD](data) {
        const result = await ddpService.addItem(CONTROLLERNAMES.BRAND, data, STORE_MODULE_NAMES.BRAND);
        if (result.isSuccess) {
            this.context.dispatch(ACTION_TYPES.GET_SELECTLIST, true);
        }
        return result;
    }
    async [_w = ACTION_TYPES.EDIT](data) {
        const result = await ddpService.editItem(CONTROLLERNAMES.BRAND, data, STORE_MODULE_NAMES.BRAND);
        if (result.isSuccess) {
            this.context.dispatch(ACTION_TYPES.GET_SELECTLIST, true);
        }
        return result;
    }
    async [_y = ACTION_TYPES.DELETE_ITEM](id) {
        const result = await ddpService.deleteItem(id, CONTROLLERNAMES.BRAND);
        if (result.isSuccess) {
            this.context.dispatch(ACTION_TYPES.GET_SELECTLIST, true);
        }
        return result.isSuccess;
    }
};
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof FailActionResult !== "undefined" && FailActionResult) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], Brand.prototype, _b, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], Brand.prototype, _c, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof PaginationRequest !== "undefined" && PaginationRequest) === "function" ? _d : Object]),
    __metadata("design:returntype", void 0)
], Brand.prototype, _e, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_f = typeof Map !== "undefined" && Map) === "function" ? _f : Object]),
    __metadata("design:returntype", void 0)
], Brand.prototype, _g, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], Brand.prototype, _h, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Promise)
], Brand.prototype, _j, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", void 0)
], Brand.prototype, _k, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_l = typeof PaginationRequest !== "undefined" && PaginationRequest) === "function" ? _l : Object]),
    __metadata("design:returntype", Promise)
], Brand.prototype, _m, null);
__decorate([
    Mutation,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_o = typeof BrandVm !== "undefined" && BrandVm) === "function" ? _o : Object]),
    __metadata("design:returntype", void 0)
], Brand.prototype, _p, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", Promise)
], Brand.prototype, _q, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_r = typeof BrandVm !== "undefined" && BrandVm) === "function" ? _r : Object]),
    __metadata("design:returntype", typeof (_s = typeof Promise !== "undefined" && Promise) === "function" ? _s : Object)
], Brand.prototype, _t, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_u = typeof BrandVm !== "undefined" && BrandVm) === "function" ? _u : Object]),
    __metadata("design:returntype", typeof (_v = typeof Promise !== "undefined" && Promise) === "function" ? _v : Object)
], Brand.prototype, _w, null);
__decorate([
    Action,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number]),
    __metadata("design:returntype", typeof (_x = typeof Promise !== "undefined" && Promise) === "function" ? _x : Object)
], Brand.prototype, _y, null);
Brand = __decorate([
    Module({ namespaced: true })
], Brand);
export default Brand;
